import React, { useMemo } from 'react';
import { useFragment } from 'react-relay';
import { graphql } from "react-relay";

import styles from './DownloadDocumentList.module.scss';
import { DownloadDocumentList_documents$key } from './__generated__/DownloadDocumentList_documents.graphql';
import { DownloadDocumentListItem_document$key } from './__generated__/DownloadDocumentListItem_document.graphql';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faFilePdf } from '@fortawesome/pro-light-svg-icons';

interface Props {
  documents: DownloadDocumentList_documents$key
}

export default function DownloadDocumentList(props: Props) {
  const documents = useFragment(graphql`
    fragment DownloadDocumentList_documents on SignatoryDocumentConnection {
      edges {
        node {
          id
        }

        ...DownloadDocumentListItem_document
      }
    }
  `, props.documents);


  return (
    <div className={styles['document-list']}>
      {documents.edges.map((edge, index) => (
        <DownloadDocumentListItem
          key={edge.node.id}
          edge={edge}
          index={index}
        />
      ))}
    </div>
  )
}

interface DownloadDocumentListItemProps {
  edge: DownloadDocumentListItem_document$key,
  index: number
}
export function DownloadDocumentListItem(props: DownloadDocumentListItemProps) {
  const edge = useFragment(graphql`
    fragment DownloadDocumentListItem_document on SignatoryDocumentEdge {
      status
      node {
        id
        title
        blob
      }
    }
  `, props.edge);

  const isDesktop = useMemo(() => window.matchMedia('(min-width: 992px)')?.matches ?? false, []);

  const blobUrl = useMemo(() => {
    const blob = b64toBlob(edge.node.blob!, 'application/pdf');
    const blobUrl = URL.createObjectURL(blob);
    return blobUrl;
  }, [edge.node]);

  return (
    <React.Fragment>
      <a
        className={ styles['document-list-item']}
        data-testid={edge.status ? `document-list-item-${edge.status}` : `document-list-item-${edge.node.id}`}
        title={edge.node.title}
        href={blobUrl}
        download
      >
        <div className={styles.left}>
          <div className={styles.icon}>
            <FontAwesomeIcon icon={faFilePdf} />
          </div>
          <div className={styles.text}>
            {isDesktop ? edge.node.title : trimText(edge.node.title, 38)}
          </div>
        </div>
        <div className={styles.right}>
          <FontAwesomeIcon icon={faDownload} />
        </div>
      </a>
    </React.Fragment>
  )
}

const DOTS = '...' as const;
function trimText(input: string, length: number) {
  if ((input.length + DOTS.length) > length) {
    return input.slice(0, length) + '...';
  }
  return input;
}

const b64toBlob = (b64Data: string, contentType='', sliceSize=512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}
