import { useState, useEffect } from 'react';
import {useFragment} from 'react-relay';
import { graphql } from "react-relay";

import useMutation, { Executor } from '../../../hooks/useMutation';
import { SignatoryBeacon_viewer$data, SignatoryBeacon_viewer$key } from './__generated__/SignatoryBeacon_viewer.graphql';
import { SignatoryBeaconMutation } from './__generated__/SignatoryBeaconMutation.graphql';

export type Viewer = Omit<SignatoryBeacon_viewer$data, " $fragmentType">;
interface ComponentProps {
  viewer: Viewer
  executor: Executor<SignatoryBeaconMutation>
  dateProvider: () => Date
}

export function beaconIsActive(viewer: Viewer) {
  const hasCompleted = viewer.status === 'REJECTED' || viewer.status === 'SIGNED' || viewer.status === 'DELETED' || viewer.status === 'ERROR';
  if (hasCompleted) return false;

  const allApproved = viewer.documents.edges.reduce((memo, edge) => memo && (edge.status === 'APPROVED' || edge.status === 'PREAPPROVED'), true);
  if (!allApproved) return false;

  const isSigner = viewer.signer;
  return !isSigner;
}

export function SignatoryBeaconComponent(props: ComponentProps) {
  const beaconActive = beaconIsActive(props.viewer);
  const dateProvider = props.dateProvider;
  const [lastActionAt, setLastActionAt] = useState(() => dateProvider());
  const [lastMutationAt, setLastMutationAt] = useState<Date | null>(null);
  const [lastTickAt, setLastTickAt] = useState(() => dateProvider());
  const beaconExecutor = props.executor;

  /*
    * Beacon logic, send beacon every 10 seconds with the latest movement timestamp
    */
  useEffect(() => {
    if (!beaconActive) return;

    if (lastMutationAt === null || (lastMutationAt.valueOf() < (dateProvider().valueOf() - 10000))) {
      beaconExecutor.execute({
        input: {
          lastActionAt: lastActionAt.toJSON()
        }
      });
      setLastMutationAt(dateProvider());
    }
  }, [beaconExecutor, lastActionAt, lastMutationAt, lastTickAt, beaconActive, dateProvider]);

  /*
  * To trigger mutation even if mouse isnt being moved
  */
  useEffect(() => {
    if (!beaconActive) return;

    const interval = setInterval(() => {
      setLastTickAt(dateProvider());
    }, 5000);

    return () => clearInterval(interval);
  }, [beaconActive, dateProvider]);

  /*
  * Track user screen actions as an idle check
  */
  useEffect(() => {
    if (!beaconActive) return;

    const mouseMoveListener = () => {
      setLastActionAt(dateProvider());  
    };
    const touchMoveListener = () => {
      setLastActionAt(dateProvider());  
    };
    const scrollListener = () => {
      setLastActionAt(dateProvider());  
    };
    const keydownListener = () => {
      setLastActionAt(dateProvider());  
    };
    const clickListener = () => {
      setLastActionAt(dateProvider());  
    };
    document.addEventListener('mousemove', mouseMoveListener);
    document.addEventListener('touchmove', touchMoveListener);
    document.addEventListener('scroll', scrollListener);
    document.addEventListener('keydown', keydownListener);
    document.addEventListener('click', clickListener);

    return () => {
      document.removeEventListener('mousemove', mouseMoveListener);
      document.addEventListener('touchmove', touchMoveListener);
      document.removeEventListener('scroll', scrollListener);
      document.removeEventListener('keydown', keydownListener);
      document.removeEventListener('click', clickListener);
    };
  }, [beaconActive, setLastActionAt, dateProvider]);

  return null;
}

interface ContainerProps {
  viewer: SignatoryBeacon_viewer$key
}
export default function SignatoryBeaconContainer(props: ContainerProps) {
  const viewer = useFragment(
    graphql`
      fragment SignatoryBeacon_viewer on SignatoryViewer {
        status
        signer

        documents {
          edges {
            status
          }
        }
      }
    `,
    props.viewer
  );

  const [beaconExecutor] = useMutation<SignatoryBeaconMutation>(graphql`
    mutation SignatoryBeaconMutation($input: SignatoryBeaconInput!) {
      signatoryBeacon(input: $input) {
        viewer {
          ...SignatoryViewerScreen_viewer
        }
      }
    }
  `);

  return (
    <SignatoryBeaconComponent
      viewer={viewer}
      executor={beaconExecutor}
      dateProvider={() => new Date()}
    />
  );
}