import useTranslate from "hooks/useTranslate";
import { EmptyErrorScreen } from "screens/ErrorScreen";

export default function DownloadExpiredScreen() {
  const translate = useTranslate();

  return (
    <EmptyErrorScreen>
      <h1>{translate('Download expired')}</h1>
      <p>
        {translate('Documents are no longer available.')}
      </p>
    </EmptyErrorScreen>
  )
}