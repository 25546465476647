import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from "react";

import Modal, {ModalProps} from './Modal';
import styles from './Modal.module.scss';

interface TextAction {
  icon: IconProp,
  text: string,
  onClick?: (event: React.MouseEvent) => void
}

interface ElementAction {
  element: React.ReactNode,
  onClick?: (event: React.MouseEvent) => void
}

export type Action = TextAction | ElementAction;

interface ActionModalProps extends Omit<ModalProps, 'title' | 'children' | 'body'> {
  show: boolean,
  actions: Action[]
}

export default function ActionsModal(props: ActionModalProps) {
  const handleAction = (event: React.MouseEvent, action: Action) => {
    event.stopPropagation();
    props.onHide();

    if (action.onClick) {
      action.onClick(event);
    }
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      body={(
        <ul>
          {props.actions.map((action, index) => (
            <ActionItem key={index} {...action} onClick={(event) => handleAction(event, action)} />
          ))}
        </ul>
      )}
      className={styles['actions-modal']}
      backdrop={true}
    />
  );
}

export function ActionItem(props: Action & {onClick: (event: React.MouseEvent) => void}) {
  return (
    <li onClick={props.onClick}>
      {"text" in props ? (
        <React.Fragment>
          <FontAwesomeIcon icon={props.icon} />
          <span>{props.text}</span>
        </React.Fragment>
      ) : props.element}
    </li>
  )
}