/**
 * @generated SignedSource<<b931c432bf31a4a04e9b0452b872ad8e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SignatoryDocumentStatus = "APPROVED" | "OPENED" | "PREAPPROVED" | "REJECTED" | "SIGNED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DocumentScreen_XmlDocumentScreen_document$data = {
  readonly blob: string | null;
  readonly signatoryViewerStatus: SignatoryDocumentStatus | null;
  readonly title: string;
  readonly " $fragmentSpreads": FragmentRefs<"DocumentApprovalActions_document" | "DocumentScreen_DownloadButton_document">;
  readonly " $fragmentType": "DocumentScreen_XmlDocumentScreen_document";
};
export type DocumentScreen_XmlDocumentScreen_document$key = {
  readonly " $data"?: DocumentScreen_XmlDocumentScreen_document$data;
  readonly " $fragmentSpreads": FragmentRefs<"DocumentScreen_XmlDocumentScreen_document">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DocumentScreen_XmlDocumentScreen_document",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "blob",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "signatoryViewerStatus",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DocumentApprovalActions_document"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DocumentScreen_DownloadButton_document"
    }
  ],
  "type": "XmlDocument",
  "abstractKey": null
};

(node as any).hash = "2cf58b1f8c308a345ec438053bce6b8c";

export default node;
