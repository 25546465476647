import { useEffect } from "react";
import LoadingScreen from "screens/LoadingScreen";
import { State } from "state";

interface Props {
  mode: 'sign' | 'download'
}

export default function CallbackScreen(props: Props) {
  useEffect(() => {
    const url = new URL(window.location.href);
    const search = url.searchParams;
    const state = new State(window.sessionStorage);
    state.hydrate(search);

    url.hash = `#/${props.mode}`
    if (state.get().authentication['signatory-token']) {
      url.searchParams.set('signatory-token', state.get().authentication['signatory-token']!);
    }
    window.location.href = url.href;
  }, []);

  return (
    <LoadingScreen />
  )
}