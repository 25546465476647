/**
 * @generated SignedSource<<eefa9b06a92fc238964a192648345f91>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DocumentScreen_PdfDocumentForm_document$data = {
  readonly id: string;
  readonly " $fragmentType": "DocumentScreen_PdfDocumentForm_document";
};
export type DocumentScreen_PdfDocumentForm_document$key = {
  readonly " $data"?: DocumentScreen_PdfDocumentForm_document$data;
  readonly " $fragmentSpreads": FragmentRefs<"DocumentScreen_PdfDocumentForm_document">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DocumentScreen_PdfDocumentForm_document",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "PdfDocument",
  "abstractKey": null
};

(node as any).hash = "5d5c6a7cd75c6e49f793185ae7a144a2";

export default node;
