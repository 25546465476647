import React, {useState} from 'react';
import { graphql } from "react-relay";
import cx from 'classnames';

import { useFragment } from 'react-relay';

import Button from '../Button/Button';
import ThumbsDownIcon from '../ThumbsDownIcon';
import ThumbsUpIcon from '../ThumbsUpIcon';

import {DocumentApprovalActions_document$key} from './__generated__/DocumentApprovalActions_document.graphql';
import {DocumentApprovalActions_ui$key} from './__generated__/DocumentApprovalActions_ui.graphql';
import useUpdateSignatoryDocumentStatus from '../../mutations/updateSignatoryDocumentStatus';

import styles from './DocumentApprovalActions.module.scss';
import useTranslate from '../../hooks/useTranslate';

interface Props {
  document: DocumentApprovalActions_document$key
  ui: DocumentApprovalActions_ui$key
  approveText?: string,
  approveDisabled?: boolean,
  onApproved: () => void
  onRejected: () => void
}

export default function DocumentApprovalActions(props: Props) {
  const [confirm, setConfirm] = useState<'APPROVE' | 'REJECT'>('APPROVE');
  const translate = useTranslate();
  const data = useFragment(graphql`
    fragment DocumentApprovalActions_document on Document {
      id
      blob
      title
    }
  `, props.document);

  const ui = useFragment(graphql`
    fragment DocumentApprovalActions_ui on SignatureOrderUI {
      disableRejection
    }
  `, props.ui);

  const [statusExecutor, statusState] = useUpdateSignatoryDocumentStatus();

  const handleApprove = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (statusState.pending) return;

    if (confirm === 'APPROVE') {
      statusExecutor.executePromise({
        input: {
          documentId: data.id,
          status: 'APPROVED'
        }
      }).then(() => {
        props.onApproved();
      });
    } else {
      setConfirm('APPROVE');
    }
  }

  const handleReject = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (statusState.pending) return;

    if (confirm === 'REJECT') {
      statusExecutor.executePromise({
        input: {
          documentId: data.id,
          status: 'REJECTED'
        }
      }).then(() => {
        props.onRejected();
      });
    } else {
      setConfirm('REJECT');
    }
  }
  
  return (
    <React.Fragment>
      {ui.disableRejection !== true ? (
        <Button
          variant="danger"
          className={cx(styles['button-reject'], {[styles.active]: confirm === 'REJECT'})}
          onClick={handleReject}
          working={statusState.pending && confirm === 'REJECT'}
          iconElement={<ThumbsDownIcon />}
          data-testid="document_reject_button"
        >
          {translate('Reject')}
        </Button>
      ) : null}
      <Button
        variant="primary"
        className={cx(styles['button-approve'], {[styles.active]: confirm === 'APPROVE'})}
        onClick={handleApprove}
        working={statusState.pending && confirm === 'APPROVE'}
        iconElement={<ThumbsUpIcon />}
        data-testid="document_approve_button"

        disabled={props.approveDisabled}
      >
        {props.approveText ?? translate('Approve')}
      </Button>
    </React.Fragment>
  )
}