import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserEdit } from '@fortawesome/pro-light-svg-icons';

import useTranslate from 'hooks/useTranslate';

import {EmptyErrorScreen} from 'screens/ErrorScreen';
import styles from 'screens/ErrorScreen/ErrorScreen.module.scss';
import SignButton from 'screens/SignatoryViewerScreen/components/SignButton';

interface Props {
  onRetry: () => void
}

export default function DownloadVerificationErrorScreen(props: Props) {
  const translate = useTranslate();

  return (
    <EmptyErrorScreen>
      <FontAwesomeIcon icon={faUserEdit} />
      <h1>{translate('The used e-ID does not match any signed documents.')}</h1>
      <SignButton
        className={styles.retryButton}
        text={translate('Retry')}
        onClick={() => props.onRetry()}
      />
    </EmptyErrorScreen>
  );
}