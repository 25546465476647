/**
 * @generated SignedSource<<a64216bf84185564985273a8f4abcafc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DocumentScreen_ResetButton_document$data = {
  readonly id: string;
  readonly " $fragmentType": "DocumentScreen_ResetButton_document";
};
export type DocumentScreen_ResetButton_document$key = {
  readonly " $data"?: DocumentScreen_ResetButton_document$data;
  readonly " $fragmentSpreads": FragmentRefs<"DocumentScreen_ResetButton_document">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DocumentScreen_ResetButton_document",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "PdfDocument",
  "abstractKey": null
};

(node as any).hash = "a2643f4a0eed60265ff7d553acf25860";

export default node;
