/**
 * @generated SignedSource<<1801e8f0b5f814ee0583e5162e90b018>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SignatoryFrontendEvent = "DOWNLOAD_LINK_OPENED" | "SIGN_LINK_OPENED" | "%future added value";
export type SignatoryStatus = "DELETED" | "ERROR" | "OPEN" | "REJECTED" | "SIGNED" | "%future added value";
export type TrackSignatoryInput = {
  event: SignatoryFrontendEvent;
};
export type trackSignatoryMutation$variables = {
  input: TrackSignatoryInput;
};
export type trackSignatoryMutation$data = {
  readonly trackSignatory: {
    readonly viewer: {
      readonly status?: SignatoryStatus;
    };
  } | null;
};
export type trackSignatoryMutation = {
  response: trackSignatoryMutation$data;
  variables: trackSignatoryMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "SignatoryViewer",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "trackSignatoryMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TrackSignatoryOutput",
        "kind": "LinkedField",
        "name": "trackSignatory",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "trackSignatoryMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TrackSignatoryOutput",
        "kind": "LinkedField",
        "name": "trackSignatory",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8de33ad5fcce8ee215a596d868524d5a",
    "id": null,
    "metadata": {},
    "name": "trackSignatoryMutation",
    "operationKind": "mutation",
    "text": "mutation trackSignatoryMutation(\n  $input: TrackSignatoryInput!\n) {\n  trackSignatory(input: $input) {\n    viewer {\n      __typename\n      ... on SignatoryViewer {\n        status\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "81905eefc94438a67a07bc9ec23e3976";

export default node;
