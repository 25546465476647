import React, {useRef, useState, useLayoutEffect, useMemo} from 'react';
import throttle from 'lodash/throttle';

interface Props {
  /**
   * Should be base64 encoded image
   */
  initialDrawing?: string
  /**
   * Returns the drawn image data when the user is drawing (throttled)
   */
  onDraw: (image: ImageData) => void,
}

interface Point {
  x: number
  y: number
}

const touchToPoint = (wrapper: HTMLElement, canvas: HTMLCanvasElement, touch: React.Touch | React.MouseEvent | MouseEvent) : Point => {
  let x = touch.clientX - canvas.getBoundingClientRect().left;
  let y = touch.clientY - canvas.getBoundingClientRect().top;
  return {x, y};
}

export default function DrawableCanvas(props: Props) {
  const {initialDrawing: initialDraw} = props;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [hasDrawn, setDrawn] = useState(false);
  const [isMouseDrawing, setMouseDrawing] = useState(false);
  const [, setPoints] = useState<Point[]>([]);

  const triggerCallback = useMemo(() => 
    throttle(
      () => {
        const canvas = canvasRef.current;
        if (!canvas) return;
        const ctx = canvas.getContext('2d')!;
        props.onDraw(ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height));
      }, 
      350,
      {leading: true, trailing: true}
    ),
    [props.onDraw]
  );

  const handleDraw = () => {
    const ctx = canvasRef.current?.getContext('2d')!;
    setPoints(points => {
      if (points.length < 2) return points;

      const first = points.shift()!;
      ctx.moveTo(first.x, first.y);
      points.forEach(point => {
        ctx.lineTo(point.x, point.y);
      });
      ctx.stroke();
      
      triggerCallback();
      setDrawn(true);
      return [points.pop()!];
    });
  };

  const drawDot = (point: Point) => {
    const ctx = canvasRef.current?.getContext('2d')!;
    ctx.fillRect(point.x, point.y,2,2);
  }
  
  const handleTouchStart = (event: React.TouchEvent) => {
    if (event.touches.length !== 1) return;
    const touch = event.touches[0];
    setPoints([touchToPoint(wrapperRef.current!, canvasRef.current!, touch)]);
  }

  const handleTouchMove = (event: React.TouchEvent) => {
    if (event.touches.length !== 1) return;
    const touch = event.touches[0];
    setPoints(points => points.concat([touchToPoint(wrapperRef.current!, canvasRef.current!, touch)]));
    handleDraw();
  }

  const handleTouchEnd = (event: React.TouchEvent) => {
    handleDraw();
  }

  const handleMouseDown = (event: React.MouseEvent) => {
    const point = touchToPoint(wrapperRef.current!, canvasRef.current!, event);
    setMouseDrawing(true);

    drawDot(point);
    setPoints([point]);
  }

  const handleMouseMove = (event: React.MouseEvent) => {
    if (!isMouseDrawing) return;
    setPoints(points => points.concat([touchToPoint(wrapperRef.current!, canvasRef.current!, event)]));
    handleDraw();
  }

  const handleMouseUp = () => {
    setMouseDrawing(false);
    setPoints([]);
  }

  useLayoutEffect(() => {
    document.addEventListener('mouseup', handleMouseUp);
    return () => document.removeEventListener('mouseup', handleMouseUp);
  }, []);

  useLayoutEffect(() => {
    if (hasDrawn) return;
    if (!initialDraw) return;

    const image = new Image();
    const loadHandler = () => {
      const ctx = canvasRef.current!.getContext('2d')!;
      const x = (wrapperRef.current!.clientWidth / 2) - (image.width / 2);
      const y = (wrapperRef.current!.clientHeight / 2) - (image.height / 2);
      ctx.drawImage(image, x, y);
    };
    image.src = `data:image/png;base64,${initialDraw}`;
    image.addEventListener('load', loadHandler);

    return () => image.removeEventListener('load', loadHandler);
  }, [hasDrawn, initialDraw]);

  return (
    <div
      ref={wrapperRef}
      /* "inline" css to avoid having to ship a stylesheet */
      style={{
        overflow: 'hidden',
        touchAction: 'none',
        overscrollBehavior: 'contain',
        height: '100%',
        width: '100%',
        zIndex: 9,
        position: 'relative',
        display: 'flex',
      }}
    >
      <canvas
        style={{
          zIndex: 10,
          position: 'absolute'
        }}
        /* Since we will end up cropping the picture anyways we can use a massive canvas to account for multiple screensizes and resizing without losing the drawn material*/
        width={3000}
        height={3000}
        ref={canvasRef}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
      />
    </div>
  );
}

function cropImageData(imgData: ImageData) : ImageData | null { // removes transparent edges
  const paintedBox = getPaintedBox(imgData);
  if (!paintedBox) return null;

  const data = imgData.data;
  const newWidth = paintedBox.right - paintedBox.left + 1;
  const newHeight = paintedBox.bottom - paintedBox.top + 1;

  const rowLength = imgData.width * 4;
  const newRowLength = newWidth * 4;
  
  const topIndex = paintedBox.top * rowLength;
  const bottomIndex = paintedBox.bottom * rowLength;

  const leftCrop = paintedBox.left * 4;

  const newData : number[] = [];

  // loop through each row
  for (let x = 0; x < data.length; x += rowLength) {
    const newRowStart = x + leftCrop;
    const newRowEnd = newRowStart + newRowLength;

    if (x >= topIndex && x <= bottomIndex) {
      for (let i = newRowStart; i < newRowEnd; i += 4) {
        newData.push(data[i], data[i + 1], data[i + 2], data[i + 3]);
      }
    }
  }

  return new ImageData(Uint8ClampedArray.from(newData), newWidth, newHeight);          
}

function getPaintedBox(imgData: ImageData) : {top: number, bottom: number, left: number, right: number} | null {
  let x, y, w, h, top, left, right, bottom, data, idx1, idx2, found;
  w = imgData.width;
  h = imgData.height;
  data = new Uint32Array(imgData.data.buffer);
  idx1 = 0;
  idx2 = w * h - 1;
  found = false; 
  // search from top and bottom to find first rows containing a non transparent pixel.
  for (y = 0; y < h && !found; y += 1) {
    for (x = 0; x < w; x += 1) {
      if (data[idx1++] && !top) {  
        top = y + 1;
        if (bottom) { // top and bottom found then stop the search
          found = true; 
          break; 
        }
      }
      if (data[idx2--] && !bottom) { 
        bottom = h - y - 1; 
        if (top) { // top and bottom found then stop the search
          found = true; 
          break;
        }
      }
    }
    if (y > h - y && !top && !bottom) { return null } // image is completely blank so do nothing
  }
  if (!top || !bottom) { return null } // image is completely blank so do nothing

  top -= 1; // correct top 
  found = false;
  // search from left and right to find first column containing a non transparent pixel.
  for (x = 0; x < w && !found; x += 1) {
    idx1 = top * w + x;
    idx2 = top * w + (w - x - 1);
    for (y = top; y <= bottom; y += 1) {
      if (data[idx1] && !left) {  
        left = x + 1;
        if (right) { // if left and right found then stop the search
          found = true; 
          break;
        }
      }
      if (data[idx2] && !right) { 
        right = w - x - 1; 
        if (left) { // if left and right found then stop the search
          found = true; 
          break;
        }
      }
      idx1 += w;
      idx2 += w;
    }
  }

  if (!left || !right) return null;

  left -= 1; // correct left
  return {bottom, top, right, left};       
}

function getBoundingBox(imgData: ImageData) : {width: number, height: number} | null {
  const paintedBox = getPaintedBox(imgData);
  if (!paintedBox) return null;

  const width = paintedBox.right - paintedBox.left;
  const height = paintedBox.bottom - paintedBox.top;

  return {width, height};
}

function imageDataToBase64(image: ImageData) {
  const canvas = document.createElement('canvas');
  canvas.width = image.width;
  canvas.height = image.height;
  canvas.getContext('2d')!.putImageData(image, 0, 0);
  return canvas.toDataURL().replace('data:image/png;base64,', '');
}

export const imageDataUtils = {crop: cropImageData, base64: imageDataToBase64, boundingBox: getBoundingBox};