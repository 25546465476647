import {useEffect} from 'react';
import {useFragment} from 'react-relay';
import cx from 'classnames';
import { graphql } from "react-relay";

import type { ReceiptScreen_viewer$key } from "./__generated__/ReceiptScreen_viewer.graphql";

import styles from './ReceiptScreen.module.scss';

import ThumbsDownIcon from 'components/ThumbsDownIcon/ThumbsDownIcon';
import ThumbsUpIcon from 'components/ThumbsUpIcon/ThumbsUpIcon';
import useTranslate from 'hooks/useTranslate';

interface Props {
  viewer: ReceiptScreen_viewer$key
  onNavigate: (url: string) => void
}

export default function ReceiptScreen(props: Props) {
  const {onNavigate} = props;
  const translate = useTranslate();

  const viewer = useFragment(
    graphql`
      fragment ReceiptScreen_viewer on SignatoryViewer {
        status
        ui {
          signatoryRedirectUri
        }

        signatoryId
      }
    `,
    props.viewer
  );

  const willRedirect = !!viewer.ui.signatoryRedirectUri;

  useEffect(() => {
    if (!viewer.ui.signatoryRedirectUri) return;

    const url = new URL(viewer.ui.signatoryRedirectUri!);
    url.searchParams.append('signatory_id', viewer.signatoryId);
    url.searchParams.append('signatory_status', viewer.status);

    const timeout = setTimeout(() => {
      onNavigate(url.toString());
    }, 500);

    return () => clearTimeout(timeout);
  }, [viewer, onNavigate]);

  if (viewer.status === "SIGNED") {
    return (
      <div className={cx(styles['receipt-screen'], styles[viewer.status])} data-testid="signatory-receipt-screen-approved">
        <h1>
          {translate('Signed')}
          {willRedirect && (<span>. <br />{translate('You will be redirected...')}</span>)}
        </h1>
        <ThumbsUpIcon />
      </div>
    );
  }

  if (viewer.status === "REJECTED") {
    return (
      <div className={cx(styles['receipt-screen'], styles[viewer.status])} data-testid="signatory-receipt-screen-rejected">
        <h1>
          {translate('You have not signed')}
          {willRedirect && (<span>. <br />{translate('You will be redirected...')}</span>)}
        </h1>
        <ThumbsDownIcon />
      </div>
    );
  }

  return null;
}