import React, { useEffect } from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';

import Sentry from '../../sentry';

import Button from '../../components/Button/Button';
import styles from './ErrorScreen.module.scss';
import useTranslate from '../../hooks/useTranslate';

interface Props {
  text?: string,
  error?: Error
}
export default function ErrorScreen(props: Props) {
  const handleRetry = () => {
    window.location.reload();
  }
  const translate = useTranslate();

  useEffect(() => {
    if (!props.error) return;
    Sentry.captureException(props.error);
  }, [props.error]);

  return (
    <div className={styles['error-screen']} data-testid="error-screen">
      <FontAwesomeIcon icon={faExclamationCircle} />

      <h1>{translate('An error occurred. Please try again in 5 minutes')}</h1>

      <p>
        {props.text}{props.text && <br />}
        {translate('Our system is temporarily down or your network connection may be unstable.')}
      </p>

      {props.error && (
        <p className={styles['error-details']} data-testid="error-screen-details">
          {props.error.message}
        </p>
      )}

      <div className={styles.retryButton}>
        <Button variant="primary" onClick={handleRetry}>Try again</Button>
      </div>
    </div>
  )
}


export function EmptyErrorScreen(props: {children: React.ReactNode, center?: boolean}) {
  return (
    <div className={cx(styles['error-screen'], {[styles.center]: props.center})}>
      {props.children}
    </div>
  );
}