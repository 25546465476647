import useTranslate from "hooks/useTranslate";
import { EmptyErrorScreen } from "screens/ErrorScreen";

export default function DownloadUnavailableScreen() {
  const translate = useTranslate();

  return (
    <EmptyErrorScreen>
      <h1>{translate('Download unavailable')}</h1>
      <p>
        {translate('Documents are not available yet, or you are not allowed to download.')}
      </p>
    </EmptyErrorScreen>
  )
}