import React, { useCallback, useState } from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { graphql } from "react-relay";
import {useFragment} from 'react-relay';
import { OAuth2Error } from '@criipto/verify-react';
import { faUserEdit } from '@fortawesome/pro-light-svg-icons';

import {State} from '../../state';
import { UnvalidatedSignatoryViewerScreen_viewer$key } from './__generated__/UnvalidatedSignatoryViewerScreen_viewer.graphql';

import styles from './UnvalidatedSignatoryViewerScreen.module.scss';
import useTranslate from 'hooks/useTranslate';

import CriiptoVerifyButtons from '../../components/CriiptoVerifyButtons/CriiptoVerifyButtons';
import OAuth2ErrorScreen from 'screens/SignatoryViewerScreen/screens/OAuth2ErrorScreen/OAuth2ErrorScreen';
import { Evidence } from 'screens/SignScreen/SignScreen';
import { EmptyErrorScreen } from 'screens/ErrorScreen/ErrorScreen';
import errorStyles from 'screens/ErrorScreen/ErrorScreen.module.scss';
import SignButton from 'screens/SignatoryViewerScreen/components/SignButton';

interface Props {
  state: State
  viewer: UnvalidatedSignatoryViewerScreen_viewer$key
  onRefresh: () => void
  userAgent: string
}

const fragmentQuery = graphql`
  fragment UnvalidatedSignatoryViewerScreen_viewer on UnvalidatedSignatoryViewer {
    ui {
      logo {
        src
        href
      }
    }

    evidenceProviders {
      __typename
      ...CriiptoVerifyButtons_evidenceProvider
    }
  }
`

export default function UnvalidatedSignatoryViewerScreen(props: Props) {
  const [oAuth2Error, setOAuth2Error] = useState<OAuth2Error | null>(null);
  const {state, onRefresh} = props;

  const viewer = useFragment(
    fragmentQuery,
    props.viewer
  );
  const translate = useTranslate();

  const resetQueryState = useCallback(() => {
    const query = new URLSearchParams(window.location.search);
    query.delete('error');
    query.delete('error_description');
    query.delete('code');
    window.history.replaceState(null, "", window.location.origin + window.location.pathname + `?${query.toString()}${window.location.hash}`);
  }, []);
  const handleReset = useCallback(() => {
    setOAuth2Error(null);
    resetQueryState();
  }, [resetQueryState]);

  const handleEvidence = useCallback((evidence: Evidence) => {
    if ("jwt" in evidence) {
      resetQueryState();
      state.setAuthentication('signatory-validation', `${evidence.id}:${evidence.jwt}`);
      state.save();
      onRefresh();
    }
  }, [resetQueryState, state, onRefresh]);

  if (state.get().authentication['signatory-validation']) {
    const handleRetry = () => {
      state.setAuthentication('signatory-validation', undefined);
      state.save();
      onRefresh();
    };

    return (
      <EmptyErrorScreen>
        <FontAwesomeIcon icon={faUserEdit} />
        <h1>{translate('The used e-ID does not match the person expected to sign these documents.')}</h1>
        <p>
          {translate('If you are signing on behalf or in company of another person, like your spouse, please ensure you are using the intended e-ID.')}
        </p>
        <SignButton
          className={errorStyles.retryButton}
          text={translate('Retry')}
          onClick={handleRetry}
        />
      </EmptyErrorScreen>
    );
  }

  if (oAuth2Error) {
    return (
      <div className={cx(styles['full-screen'])}>
        <OAuth2ErrorScreen
          onRetry={handleReset} 
          error={oAuth2Error}
          headline={translate('An error occurred while authenticating your e-ID.')}
        />
      </div>
    );
  }

  return (
    <div className={styles['unvalidated-signatory-viewer-screen']} data-testid="signatory-viewer-screen">
      <header className={cx({[styles['with-logo']]: !!viewer.ui.logo})}>
        {viewer.ui.logo ? (
          <div className={styles.logo}>
            {viewer.ui.logo.href ? (
              <a href={viewer.ui.logo.href}>
                <img src={viewer.ui.logo.src} alt="" />
              </a>
            ) : (
              <img src={viewer.ui.logo.src} alt="" />
            )}
          </div>
        ) : null}
        <h1>{translate('You must identify yourself before you can view and sign the documents.')}</h1>
      </header>

      <div className={styles['providers']}>
        {viewer.evidenceProviders.map(evidenceProvider => (
          <React.Fragment key={evidenceProvider.__typename}>
            <CriiptoVerifyButtons
              userAgent={props.userAgent}
              evidenceProvider={evidenceProvider}
              onEvidence={handleEvidence}
              onError={setOAuth2Error}
              state={props.state}
              mayImmediateRedirect={false}
              action="login"
            />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}