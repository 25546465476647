import jwtDecode from 'jwt-decode';
import {Environment, Network, RecordSource, Store} from 'relay-runtime';
import {Authentication, State} from './state';

const MINUTE = 60 * 1000;
const EXPIRATION = MINUTE * 20;

export function getAuthentication(state: State) : Authentication {
  const validation = state.get().authentication['signatory-validation'];
  const decoded = validation ? jwtDecode<{iat: number}>(validation) : null;
  const issuedAt = decoded?.iat ? decoded.iat * 1000 : null; 
  const expiresAt = issuedAt ? issuedAt + EXPIRATION : null;
  const isExpired = expiresAt ? expiresAt <= Date.now() : null

  return {
    'signatory-token': state.get().authentication['signatory-token'],
    'signatory-validation': isExpired ? undefined : validation
  };
}

export function extractOperation(query: string) : {type: 'query' | 'mutation', operation: string} | null {
  if (query.startsWith('query ')) {
    const match = query.match(/query (.+?) {/);
    if (!match) return null;
    return {type: 'query', operation: match[1]};
  }
  if (query.startsWith('mutation ')) {
    const match = query.match(/mutation (.+?) {/);
    if (!match) return null;
    return {type: 'mutation', operation: match[1]};
  }
  return null;
}

async function fetchGraphQL(text : string, variables : any, authentication: Authentication) {
  if (!import.meta.env.VITE_GRAPHQL_URI) throw new Error('VITE_GRAPHQL_URI not configured');
  
  const headers : any = {
    ...(authentication['signatory-token'] ? {
      'Signatory-Token': authentication['signatory-token']
    } : {}),
    ...(authentication['signatory-validation'] ? {
      'Signatory-Validation': authentication['signatory-validation']
    } : {}),
    'Content-Type': 'application/json',
  }

  try {
    const response = await fetch(import.meta.env.VITE_GRAPHQL_URI!, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        query: text,
        variables,
      }),
    });

    // Get the response as JSON
    return await response.json();
  } catch (err) {
    const operation = extractOperation(text);
    throw new Error(`Failed to execute GraphQL operation (${operation?.type ?? 'unknown'} ${operation?.operation ?? 'unknown'}): ${err?.toString()}`);
  }
}

function fetchRelayFactory(state: State) {
  return async (params : any, variables : any) => {
    return fetchGraphQL(params.text, variables, getAuthentication(state));
  }
}

export default function environmentFactory(state: State) {
  return new Environment({
    network: Network.create(fetchRelayFactory(state)),
    store: new Store(new RecordSource()),
  });
}