/**
 * @generated SignedSource<<5b8abadc8cccf0facaff447d4c06f8b8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UnvalidatedSignatoryViewerScreen_viewer$data = {
  readonly evidenceProviders: ReadonlyArray<{
    readonly __typename: string;
    readonly " $fragmentSpreads": FragmentRefs<"CriiptoVerifyButtons_evidenceProvider">;
  }>;
  readonly ui: {
    readonly logo: {
      readonly href: string | null;
      readonly src: string;
    } | null;
  };
  readonly " $fragmentType": "UnvalidatedSignatoryViewerScreen_viewer";
};
export type UnvalidatedSignatoryViewerScreen_viewer$key = {
  readonly " $data"?: UnvalidatedSignatoryViewerScreen_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"UnvalidatedSignatoryViewerScreen_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UnvalidatedSignatoryViewerScreen_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SignatureOrderUI",
      "kind": "LinkedField",
      "name": "ui",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SignatureOrderUILogo",
          "kind": "LinkedField",
          "name": "logo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "src",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "href",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "evidenceProviders",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CriiptoVerifyButtons_evidenceProvider"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UnvalidatedSignatoryViewer",
  "abstractKey": null
};

(node as any).hash = "bfb933213a2f110f397012066dbc8086";

export default node;
