/**
 * @generated SignedSource<<d9e9c1381bbc39af7e4453ee1cb05527>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CriiptoVerifyButtons_evidenceProvider$data = {
  readonly __typename: "CriiptoVerifySignatureEvidenceProvider";
  readonly acrValues: ReadonlyArray<string>;
  readonly alwaysRedirect: boolean;
  readonly clientID: string;
  readonly domain: string;
  readonly id: string;
  readonly loginHint: string | null;
  readonly message: string | null;
  readonly scope: string | null;
  readonly " $fragmentType": "CriiptoVerifyButtons_evidenceProvider";
} | {
  readonly __typename: "OidcJWTSignatureEvidenceProvider";
  readonly acrValues: ReadonlyArray<string>;
  readonly alwaysRedirect: boolean;
  readonly clientID: string;
  readonly domain: string;
  readonly id: string;
  readonly " $fragmentType": "CriiptoVerifyButtons_evidenceProvider";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "CriiptoVerifyButtons_evidenceProvider";
};
export type CriiptoVerifyButtons_evidenceProvider$key = {
  readonly " $data"?: CriiptoVerifyButtons_evidenceProvider$data;
  readonly " $fragmentSpreads": FragmentRefs<"CriiptoVerifyButtons_evidenceProvider">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "domain",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientID",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "acrValues",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "alwaysRedirect",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CriiptoVerifyButtons_evidenceProvider",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/)
      ],
      "type": "OidcJWTSignatureEvidenceProvider",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "message",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "loginHint",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "scope",
          "storageKey": null
        }
      ],
      "type": "CriiptoVerifySignatureEvidenceProvider",
      "abstractKey": null
    }
  ],
  "type": "SignatureEvidenceProvider",
  "abstractKey": "__isSignatureEvidenceProvider"
};
})();

(node as any).hash = "61b3930855bb189efa7dd6d0018de67e";

export default node;
