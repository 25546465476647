import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserEdit } from '@fortawesome/pro-light-svg-icons';
import useTranslate from 'hooks/useTranslate';

import {EmptyErrorScreen} from 'screens/ErrorScreen';
import styles from 'screens/ErrorScreen/ErrorScreen.module.scss';
import { OAuth2Error } from '@criipto/verify-react';
import SignButton from '../../components/SignButton';

interface Props {
  onRetry: () => void
  error: OAuth2Error,
  headline?: string
}

export default function OAuth2ErrorScreen(props: Props) {
  const translate = useTranslate();

  return (
    <EmptyErrorScreen>
      <FontAwesomeIcon icon={faUserEdit} />
      <h1>{props.headline ?? translate('An error occurred while authenticating your e-ID to sign these documents.')}</h1>
      <p>
        {props.error.error} - {props.error.error_description}
      </p>
      <React.Fragment>
        <SignButton
          className={styles.retryButton}
          text={translate('Try again')}
          onClick={() => props.onRetry()}
        />
      </React.Fragment>
    </EmptyErrorScreen>
  );
}