/**
 * @generated SignedSource<<df2a56c6524b1560e3c1dd041dccce31>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SignatoryStatus = "DELETED" | "ERROR" | "OPEN" | "REJECTED" | "SIGNED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ReceiptScreen_viewer$data = {
  readonly signatoryId: string;
  readonly status: SignatoryStatus;
  readonly ui: {
    readonly signatoryRedirectUri: string | null;
  };
  readonly " $fragmentType": "ReceiptScreen_viewer";
};
export type ReceiptScreen_viewer$key = {
  readonly " $data"?: ReceiptScreen_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReceiptScreen_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReceiptScreen_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SignatureOrderUI",
      "kind": "LinkedField",
      "name": "ui",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "signatoryRedirectUri",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "signatoryId",
      "storageKey": null
    }
  ],
  "type": "SignatoryViewer",
  "abstractKey": null
};

(node as any).hash = "68cb25d442d9658cf08022bb870bfeb8";

export default node;
