/**
 * @generated SignedSource<<80cc195c440cb8c4878b92d59afc5d52>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SignatoryDocumentStatus = "APPROVED" | "OPENED" | "PREAPPROVED" | "REJECTED" | "SIGNED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DownloadDocumentListItem_document$data = {
  readonly node: {
    readonly blob: string | null;
    readonly id: string;
    readonly title: string;
  };
  readonly status: SignatoryDocumentStatus | null;
  readonly " $fragmentType": "DownloadDocumentListItem_document";
};
export type DownloadDocumentListItem_document$key = {
  readonly " $data"?: DownloadDocumentListItem_document$data;
  readonly " $fragmentSpreads": FragmentRefs<"DownloadDocumentListItem_document">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DownloadDocumentListItem_document",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "blob",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SignatoryDocumentEdge",
  "abstractKey": null
};

(node as any).hash = "06728adcc97161f5ca6f9b397339dbb5";

export default node;
