/**
 * @generated SignedSource<<9df0213d0c8a55f8eb72c3b9b8265d92>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SignatoryDocumentStatus = "APPROVED" | "OPENED" | "PREAPPROVED" | "REJECTED" | "SIGNED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DocumentScreen_PdfDocumentScreen_viewer$data = {
  readonly documents: {
    readonly edges: ReadonlyArray<{
      readonly status: SignatoryDocumentStatus | null;
    }>;
  };
  readonly ui: {
    readonly " $fragmentSpreads": FragmentRefs<"DocumentApprovalActions_ui">;
  };
  readonly " $fragmentType": "DocumentScreen_PdfDocumentScreen_viewer";
};
export type DocumentScreen_PdfDocumentScreen_viewer$key = {
  readonly " $data"?: DocumentScreen_PdfDocumentScreen_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"DocumentScreen_PdfDocumentScreen_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DocumentScreen_PdfDocumentScreen_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SignatureOrderUI",
      "kind": "LinkedField",
      "name": "ui",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DocumentApprovalActions_ui"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SignatoryDocumentConnection",
      "kind": "LinkedField",
      "name": "documents",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SignatoryDocumentEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SignatoryViewer",
  "abstractKey": null
};

(node as any).hash = "340ab443c7a78b8b9df1cbea59900661";

export default node;
