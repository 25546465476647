/**
 * @generated SignedSource<<996aef1bc923dd0a974b1aa49335b1d8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SignatoryDocumentStatus = "APPROVED" | "OPENED" | "PREAPPROVED" | "REJECTED" | "SIGNED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DocumentScreen_PdfDocumentScreen_document$data = {
  readonly blob: string | null;
  readonly form: {
    readonly enabled: boolean;
  } | null;
  readonly id: string;
  readonly signatoryViewerStatus: SignatoryDocumentStatus | null;
  readonly title: string;
  readonly " $fragmentSpreads": FragmentRefs<"DocumentApprovalActions_document" | "DocumentScreen_DownloadButton_document" | "DocumentScreen_PdfDocumentForm_document" | "DocumentScreen_ResetButton_document">;
  readonly " $fragmentType": "DocumentScreen_PdfDocumentScreen_document";
};
export type DocumentScreen_PdfDocumentScreen_document$key = {
  readonly " $data"?: DocumentScreen_PdfDocumentScreen_document$data;
  readonly " $fragmentSpreads": FragmentRefs<"DocumentScreen_PdfDocumentScreen_document">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DocumentScreen_PdfDocumentScreen_document",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "blob",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "signatoryViewerStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PdfDocumentForm",
      "kind": "LinkedField",
      "name": "form",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "enabled",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DocumentScreen_PdfDocumentForm_document"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DocumentApprovalActions_document"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DocumentScreen_DownloadButton_document"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DocumentScreen_ResetButton_document"
    }
  ],
  "type": "PdfDocument",
  "abstractKey": null
};

(node as any).hash = "8a4a5906ae90b203efb230cbeb2ffb70";

export default node;
