import 'core-js/actual/structured-clone';
import 'core-js/actual/array/at';
import 'core-js/actual/typed-array/at';
import 'core-js/actual/string/at';
import 'core-js/actual/promise/all-settled';
import 'core-js/actual/global-this';

function at(n) {
	// ToInteger() abstract op
	n = Math.trunc(n) || 0;
	// Allow negative indexing from the end
	if (n < 0) n += this.length;
	// OOB access is guaranteed to return undefined
	if (n < 0 || n >= this.length) return undefined;
	// Otherwise, this is just normal property access
	return this[n];
}

Object.defineProperty(Array.prototype, "at", { value: at,
	writable: true,
	enumerable: false,
	configurable: true
});

if (typeof globalThis === "undefined") {
	window.globalThis = window;
}