import React from "react";
import cx from 'classnames';

import Modal, {ModalProps} from './Modal';
import Button, {ButtonProps} from '../Button';

import styles from './Modal.module.scss';
import useTranslate from "../../hooks/useTranslate";

export interface ConfirmModalProps extends Omit<ModalProps, 'onHide' | 'body'> {
  title: string,
  show: boolean,
  children?: React.ReactNode
  onCancel: () => void
  onConfirm: () => void,
  working?: boolean,
  'data-testid-prefix'?: string,
  variant?: ButtonProps['variant'],
  disabled?: boolean,
  cancelButtonProps?: Partial<ButtonProps>,
  continueButtonProps?: Partial<ButtonProps>,
  withForm?: boolean
}

export default function ConfirmModal(props: ConfirmModalProps) {
  const translate = useTranslate();

  return (
    <Modal
      title={props.title}
      show={props.show}
      onHide={props.onCancel}
      body={props.children}
      className={cx(styles['confirm-modal'])}
      variant={props.variant}
      closeButton={false}
      backdrop={true}
      withForm={props.withForm}
      onSubmit={props.onConfirm}
      footer={(
        <React.Fragment>
          <Button
            type="button"
            variant="default"
            className={styles.button}
            onClick={() => props.onCancel()}
            disabled={props.working}
            data-testid={`${props["data-testid-prefix"] || ''}confirm_modal_cancel`}
            {...props.cancelButtonProps}
          >
            {props.cancelButtonProps?.children === false ? null : translate('Cancel')}
          </Button>
          <Button
            type="submit"
            variant={props.variant || 'primary'}
            className={styles.button}
            onClick={() => props.onConfirm()}
            disabled={props.disabled}
            working={props.working}
            data-testid={`${props["data-testid-prefix"] || ''}confirm_modal_confirm`}
            {...props.continueButtonProps}
          >
            {props.continueButtonProps?.children === false ? null : translate('Continue')}
          </Button>
        </React.Fragment>
      )}
    />
  );
}