/**
 * @generated SignedSource<<9ddf71b152ddee750d13c54ea2a2c1b2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AllOfEvidenceProviderScreen_evidenceProvider$data = {
  readonly id: string;
  readonly providers: ReadonlyArray<{
    readonly __typename: string;
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"CriiptoVerifyButtons_evidenceProvider" | "DrawableEvidenceProviderScreen">;
  }>;
  readonly " $fragmentType": "AllOfEvidenceProviderScreen_evidenceProvider";
};
export type AllOfEvidenceProviderScreen_evidenceProvider$key = {
  readonly " $data"?: AllOfEvidenceProviderScreen_evidenceProvider$data;
  readonly " $fragmentSpreads": FragmentRefs<"AllOfEvidenceProviderScreen_evidenceProvider">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "CriiptoVerifyButtons_evidenceProvider"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AllOfEvidenceProviderScreen_evidenceProvider",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "providers",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": (v1/*: any*/),
          "type": "OidcJWTSignatureEvidenceProvider",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v1/*: any*/),
          "type": "CriiptoVerifySignatureEvidenceProvider",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "DrawableEvidenceProviderScreen"
            }
          ],
          "type": "DrawableSignatureEvidenceProvider",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AllOfSignatureEvidenceProvider",
  "abstractKey": null
};
})();

(node as any).hash = "d88cdee45e16a95d35be760f79bf6f8b";

export default node;
