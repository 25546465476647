import React from "react";
import ReactDOM from 'react-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import cx from 'classnames';

import {ButtonProps} from '../Button';

import styles from './Modal.module.scss';

export interface ModalProps {
  title?: string,
  show: boolean,
  body: React.ReactNode
  footer?: React.ReactNode,
  onHide: () => void,
  className?: string,
  backdrop?: boolean,
  closeButton?: boolean,
  withForm?: boolean,
  variant?: ButtonProps['variant'],
  onSubmit?: () => void
}

export default function Modal(props: ModalProps) {
  const {withForm, onSubmit} = props;
  const handleClose = (event: React.MouseEvent) => {
    event.stopPropagation();
    props.onHide();
  }

  const content = (
    <div className={styles['modal-content']}>
      {(props.title || props.closeButton !== false) ? (
        <div className={styles['modal-header']}>
          {props.title && (<h5 className={cx(styles['modal-title'], `text-${props.variant || 'primary'}`)}>{props.title}</h5>)}
          {props.closeButton !== false ? (
            <button type="button" className={styles['close']} aria-label="Close" onClick={handleClose}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          ) : null}
        </div>
      ) : null}
      <div className={styles['modal-body']}>
        {props.body}
      </div>
      {props.footer && (
        <div className={styles['modal-footer']}>
          {props.footer}
        </div>
      )}
    </div>
  );

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (onSubmit) onSubmit();
  }

  return ReactDOM.createPortal((
    <React.Fragment>
      {props.backdrop && props.show && (<div className={styles['modal-backdrop']} />)}
      <div
        className={
          cx(
            styles.modal,
            styles.fade,
            props.className,
            {
              [styles.show]: props.show,
              [styles['form-modal']]: props.withForm
            }
          )
        }
        tabIndex={-1}
        role="dialog"
      >
        {withForm ? (
          <div className={styles['modal-dialog']} role="document" onSubmit={handleSubmit}>
            {content}
          </div>
        ) : (
          <div className={styles['modal-dialog']} role="document">
            {content}
          </div>
        )}
      </div>
    </React.Fragment>
  ), document.getElementById('modal-root')!);
}