import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import {cssClass} from '../../utils';
import primaryLoader from '../../assets/loader-white-blue.gif';
import defaultLoader from '../../assets/loader-blue-white.gif';
import dangerLoader from '../../assets/loader-white-red.gif';

import styles from './Button.module.scss';

export interface ButtonProps {
  variant: 'primary' | 'default' | 'danger' | 'transparent' | 'transparent-bordered' | 'transparent-danger'
  working?: boolean
  className?: string
  icon?: IconProp
  iconElement?: React.ReactNode,
  iconPosition?: 'left' | 'right',
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  children?: React.ReactNode
  style?: React.CSSProperties
  large?: boolean
  type?: 'submit' | 'button'
  disabled?: boolean
  autoWidth?: boolean,
  'data-testid'?: string
}

export default function Button(props: ButtonProps) {
  const {variant, onClick, children, working, icon, iconElement, style, large, className, type, disabled, autoWidth} = props;
  const iconPosition = props.iconPosition || 'left';
  const iconRender =
    working ? (
      <React.Fragment>
        {variant === 'danger' ? (
          <img src={dangerLoader} alt="Loading spinner" />
        ) : variant === 'default' ? (
          <img src={defaultLoader} alt="Loading spinner" />
        ) : (
          <img src={primaryLoader} alt="Loading spinner" />
        )}
      </React.Fragment>
    ) : icon ? (
      <FontAwesomeIcon icon={icon} />
    ) : iconElement ? iconElement : null

  return (
    <button
      className={cssClass({
        private: [
          styles.button,
          styles['button--'+variant],
          {
            [styles['button--large']]: large,
            [styles['button--icon']]: (iconElement || icon) && !children,
            [styles['button--autoWidth']]: autoWidth,
          },
          className
        ],
        public: [`button button--${variant}`, {'button--large': large}]
      })}
      onClick={onClick}
      disabled={working || disabled}
      style={style}
      type={type || 'button'}
      data-testid={props['data-testid']}
    >
      {iconPosition === 'left' ? iconRender : null}
      {children ? (<span>{children}</span>) : null}
      {iconPosition === 'right' ? iconRender : null}
    </button>
  )
}
