import { useState } from 'react';
import { graphql } from "react-relay";

import useMutation from '../../../hooks/useMutation';
import ConfirmModal from '../../../components/Modal/ConfirmModal';
import useTranslate from '../../../hooks/useTranslate';
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-light-svg-icons';

interface Props {
  onCancel: () => void
  onReject?: () => void
  show: boolean
}

export default function RejectSignatureOrderModal(props: Props) {
  const translate = useTranslate();
  const [reason, setReason] = useState('');
  const [rejectExecutor, rejectStatus] = useMutation(graphql`
    mutation RejectSignatureOrderModalMutation($input: RejectSignatureOrderInput!) {
      rejectSignatureOrder(input: $input) {
        viewer {
          ... on SignatoryViewer {
            status
            signer
          }
        }
      }
    }
  `);

  const handleConfirm = () => {
    rejectExecutor.executePromise({
      input: {
        dummy: true,
        reason
      }
    }).then(() => {
      if (props.onReject) props.onReject();
    });
  };
  const handleCancel = () => props.onCancel();

  return (
    <ConfirmModal
      working={rejectStatus.pending}
      show={props.show}
      title={translate('I do not want to sign')}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      data-testid-prefix="signatory_reject_"
      variant="default"
      withForm={true}
      cancelButtonProps={{
        icon: faAngleLeft,
        children: false
      }}
      continueButtonProps={{
        icon: faAngleRight,
        iconPosition: 'right',
        disabled: !reason,
        children: rejectStatus.pending ? false : undefined
      }}
    >
      <textarea
        onChange={event => setReason(event.target.value)}
        data-testid="signatory_reject_reason"
        placeholder={translate('Let us know why you do not want to sign.')}
        value={reason}
      />
    </ConfirmModal>
  )
}