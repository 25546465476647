import React from 'react';
import useTranslate from '../../hooks/useTranslate';

import styles from './AnonymousViewerScreen.module.scss';

export default function AnonymousViewerScreen() {
  const translate = useTranslate();
  return (
    <div className={styles['anonymous-viewer-screen']}>
      <h1>{translate('An error occurred')}</h1>
      <p>
        {translate('You have ended up here by mistake, please go back to the person that invited you to sign and get a proper signature link.')}
      </p>
    </div>
  )
}