/**
 * @generated SignedSource<<16c50d57238cd48d28bbeca57a67ec9f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DocumentApprovalActions_ui$data = {
  readonly disableRejection: boolean;
  readonly " $fragmentType": "DocumentApprovalActions_ui";
};
export type DocumentApprovalActions_ui$key = {
  readonly " $data"?: DocumentApprovalActions_ui$data;
  readonly " $fragmentSpreads": FragmentRefs<"DocumentApprovalActions_ui">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DocumentApprovalActions_ui",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "disableRejection",
      "storageKey": null
    }
  ],
  "type": "SignatureOrderUI",
  "abstractKey": null
};

(node as any).hash = "b0cf7a6c65c85b4f395d3667818e88f5";

export default node;
