import './polyfill';
import Sentry from './sentry';
import React from 'react';
import ReactDOM from 'react-dom';

import {
  createRoutesFromElements,
  Route,
  RouterProvider,
  createHashRouter
} from "react-router-dom";

import reportWebVitals from './reportWebVitals';

import {
  RelayEnvironmentProvider
} from 'react-relay/hooks';

import './index.scss';

import RelayEnvironmentFactory from './RelayEnvironment';

import '@criipto/auth-js/dist/index.css';

import App from './App';
// import ErrorBoundary from './components/ErrorBoundary';
import ErrorScreen from './screens/ErrorScreen';
import LoadingScreen from './screens/LoadingScreen';

import { State } from './state';
import DownloadScreen from 'screens/DownloadScreen';
import DocumentScreen from 'screens/DocumentScreen';
import SignScreen from 'screens/SignScreen';
import RouteNotFoundScreen from 'screens/RouteNotFoundScreen/RouteNotFoundScreen';
import CallbackScreen from 'screens/CallbackScreen/CallbackScreen';

const search = new URLSearchParams(window.location.search);
const state = new State(window.sessionStorage);
state.hydrate(search);
const relayEnvironment = RelayEnvironmentFactory(state);

function DefaultErrorBoundary(props: {children: React.ReactElement}) {
  return (
    <Sentry.ErrorBoundary fallback={({error}) => <ErrorScreen error={error} />}>
      {props.children}
    </Sentry.ErrorBoundary>
  );
}

const router = createHashRouter(
  createRoutesFromElements(
    <Route>
      <Route path="*" element={<RouteNotFoundScreen />} />
      <Route path="/" element={(
        <DefaultErrorBoundary>
          <App state={state} userAgent={navigator.userAgent} />
        </DefaultErrorBoundary>
      )}>
        <Route path="documents/:documentId" element={<DefaultErrorBoundary><DocumentScreen mode="sign" /></DefaultErrorBoundary>} />
        <Route path="sign" element={<DefaultErrorBoundary><SignScreen state={state} userAgent={navigator.userAgent} /></DefaultErrorBoundary>} />
      </Route>
      <Route path="/callback/sign" element={<DefaultErrorBoundary><CallbackScreen mode="sign" /></DefaultErrorBoundary>}></Route>
      <Route path="/callback/download" element={<DefaultErrorBoundary><CallbackScreen mode="download" /></DefaultErrorBoundary>}></Route>
      <Route path="/download" element={<DefaultErrorBoundary><DownloadScreen state={state} userAgent={navigator.userAgent}  /></DefaultErrorBoundary>}>
        <Route path="documents/:documentId" element={<DefaultErrorBoundary><DocumentScreen mode="download" /></DefaultErrorBoundary>} />
      </Route>
    </Route>
  )
)

ReactDOM.render(
  <React.StrictMode>
    <RelayEnvironmentProvider environment={relayEnvironment}>
      <React.Suspense fallback={<LoadingScreen />}>
        <Sentry.ErrorBoundary fallback={({error}) => <ErrorScreen error={error} />}>
          <RouterProvider router={router} />
        </Sentry.ErrorBoundary>
      </React.Suspense>      
    </RelayEnvironmentProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
