import React from 'react';
import { useLocation } from 'react-router-dom';

import styles from './RouteNotFoundScreen.module.scss';

export default function RouteNotFoundScreen() {
  const location = useLocation();

  return (
    <div className={styles['route-not-found-screen']}>
      <h1>An error occurred: 404, route not found</h1>
      <p>
        Contact support with a screenshot of this screen.<br />
        404 on route: {location.pathname}
      </p>
    </div>
  )
}