
import styles from './LoadingScreen.module.scss';
import loader from './loader.gif';
import React from 'react';

interface Props {
  text?: string,
  children?: React.ReactNode
}

export default function LoadingScreen(props: Props) {
  return (
    <div className={styles['loading-screen']}>
      <img src={loader} alt="Loading gif" />
      {props.text && (<h1>{props.text}</h1>)}
      {props.children}
    </div>
  )
}