import React, {useEffect, useReducer} from 'react';
import {useFragment} from 'react-relay';
import cx from 'classnames';

import { graphql } from "react-relay";

import { SignatoryViewerScreen_viewer$key } from "./__generated__/SignatoryViewerScreen_viewer.graphql";

import { faBan } from '@fortawesome/pro-light-svg-icons';

import styles from './SignatoryViewerScreen.module.scss';
import DocumentList from './components/DocumentList';
import LoadingScreen from '../LoadingScreen';
import RejectSignatureOrderModal from './components/RejectSignatureOrderModal';
import Button from '../../components/Button/Button';
import ReceiptScreen from './screens/ReceiptScreen/ReceiptScreen';
import { State } from '../../state';
import ActionsMenu from '../../components/ActionsMenu';
import useTranslate from '../../hooks/useTranslate';
import SignButton from './components/SignButton';
import SignatoryDeletedScreen from './screens/SignatoryDeletedScreen';
import SignatureOrderNotOpenScreen from './screens/SignatureOrderNotOpen';
import SignatoryBeacon from './components/SignatoryBeacon';
import { useNavigate } from 'react-router-dom';
import useTrackSignatoryMutation from 'mutations/trackSignatory';

interface Props {
  viewer: SignatoryViewerScreen_viewer$key,
  onNavigate: (url: string) => void,
  state: State
}

const fragmentQuery = graphql`
  fragment SignatoryViewerScreen_viewer on SignatoryViewer {
    status
    signatureOrderStatus
    signer

    ui {
      logo {
        src
        href
      }
      disableRejection
    }

    ... ReceiptScreen_viewer

    documents {
      edges {
        status
        node {
          id
        }
      }
      ...DocumentList_documents
    }

    ...SignatoryBeacon_viewer
  }
`

export default function SignatoryViewerScreen(props: Props) {
  const [showRejectConfirm, toggleRejectConfirm] = useReducer(show => !show, false);
  const translate = useTranslate();
  const navigate = useNavigate();

  const viewer = useFragment(
    fragmentQuery,
    props.viewer
  );
  const isSigner = viewer.signer;
  const allApproved = viewer.documents.edges.reduce((memo, edge) => memo && (edge.status === 'APPROVED' || edge.status === 'PREAPPROVED'), true);
  const allPreapproved = viewer.documents.edges.reduce((memo, edge) => memo && edge.status === 'PREAPPROVED', true);
  const anyRejected = viewer.documents.edges.reduce((memo, edge) => memo || edge.status === 'REJECTED', false);

  useEffect(() => {
    if (!allPreapproved) return;
    if (!isSigner) return;
    navigate('/sign');
  }, [allPreapproved, isSigner, navigate])

  useEffect(() => {
    if (viewer.status !== 'OPEN') return;
    if (allPreapproved) return;
    if (viewer.signatureOrderStatus !== "OPEN") return;

    const documentsCount = viewer.documents.edges.length;
    if (documentsCount !== 1) return;

    const document = viewer.documents.edges[0].node;

    navigate(`/documents/${document.id}`);
  }, [viewer.documents, allPreapproved, viewer.status, navigate, viewer.signatureOrderStatus]);

  const [trackSignatory] = useTrackSignatoryMutation();
  useEffect(() => {
    trackSignatory.execute({
      input: {
        event: 'SIGN_LINK_OPENED'
      }
    })
  }, [trackSignatory]);

  if (viewer.status === "SIGNED") {
    return <ReceiptScreen viewer={viewer} onNavigate={props.onNavigate} />;
  }

  if (viewer.status === "REJECTED") {
    return <ReceiptScreen viewer={viewer} onNavigate={props.onNavigate} />;
  }

  if (viewer.status === "DELETED") {
    return <SignatoryDeletedScreen />
  }

  if (viewer.signatureOrderStatus !== "OPEN") {
    return <SignatureOrderNotOpenScreen status={viewer.signatureOrderStatus} />;
  }

  if (viewer.status === "ERROR") {
    return (
      <div>
        {translate('An error has occurred with the signature process.')}
      </div>
    );
  }

  if (allPreapproved) {
    if (!isSigner) return (
      <React.Fragment>
        <SignatoryBeacon viewer={viewer} />
        <LoadingScreen text={translate('Hold on a second...')} />
      </React.Fragment>
    );

    return null;
  }

  return (
    <React.Fragment>
      <SignatoryBeacon viewer={viewer} />
      <div className={cx(styles['signatory-viewer-screen'], {[styles.anyRejected]: anyRejected})} data-testid="signatory-viewer-screen">
        <header className={cx({[styles['with-logo']]: !!viewer.ui.logo})}>
          <div className={styles.actions}>
            <ActionsMenu
              actions={[
                (viewer.ui.disableRejection === true) ? (undefined as any) : {icon: faBan, text: translate('I do not want to sign'), onClick: toggleRejectConfirm},
              ].filter(i => i)}
            />
          </div>
          {viewer.ui.logo ? (
            <div className={styles.logo}>
              {viewer.ui.logo.href ? (
                <a href={viewer.ui.logo.href}>
                  <img src={viewer.ui.logo.src} alt="" />
                </a>
              ) : (
                <img src={viewer.ui.logo.src} alt="" />
              )}
            </div>
          ) : null}
          <h1 className={cx({'text-danger': anyRejected})}>
            {allApproved ?
              translate('Sign') :
              anyRejected ? translate('You must approve all documents before you can sign') :
                viewer.documents.edges.length === 1 ? 
                  translate('Open and approve the document below') :
                  translate('Open and approve the {documents} documents below', {documents: viewer.documents.edges.length})
            }
          </h1>
        </header>

        <DocumentList documents={viewer.documents} />

        <RejectSignatureOrderModal show={showRejectConfirm} onCancel={toggleRejectConfirm} />

        <div className={styles.bottomDrawer}>
          {anyRejected && (
            <Button variant="default" onClick={toggleRejectConfirm} data-testid="signatory_reject_button">
              {translate('I do not want to sign')}
            </Button>
          )}
          <SignButton
            className={styles.signButton}
            data-testid="sign_button"
            disabled={!allApproved || !viewer.signer}
            tooltip={
              !allApproved ? translate('You must approve all documents before you can sign') :
              !viewer.signer ? translate('Hold on a second...') : undefined
            }
            onClick={() => navigate('/sign')}
          />
        </div>
      </div>
    </React.Fragment>
  );
}