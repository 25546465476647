import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faEllipsisH } from '@fortawesome/pro-solid-svg-icons';

import ActionsModal, {Action, ActionItem} from '../Modal/ActionsModal';

import styles from './ActionsMenu.module.scss';

interface Props {
  actions: Action[]
}

export default function ActionsMenu(props: Props) {
  const {actions} = props;
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsOpen(true);
  }

  const handleAction = (event: React.MouseEvent, action: Action) => {
    event.stopPropagation();
    setIsOpen(false);
    if (action.onClick) {
      action.onClick(event);
    }
  }

  if (actions.length === 0) return null;

  return (
    <div className={styles.menu}>
      <button className={styles.mobile} onClick={handleOpen}>
        <FontAwesomeIcon icon={faEllipsisH} />
      </button>
      <ActionsModal
        show={isOpen}
        onHide={() => setIsOpen(false)}
        actions={props.actions}
      />
      <div className={styles.desktop}>
        <ul>
          {actions.map((action, index) => (
            <ActionItem key={index} {...action} onClick={(event) => handleAction(event, action)} />
          ))}
        </ul>
      </div>
    </div>
  );
}