import { graphql } from "react-relay";

import useMutation from '../hooks/useMutation';
import { trackSignatoryMutation } from './__generated__/trackSignatoryMutation.graphql';

export default function useTrackSignatoryMutation() {
  return useMutation<trackSignatoryMutation>(graphql`
    mutation trackSignatoryMutation($input: TrackSignatoryInput!) {
      trackSignatory(input: $input) {
        viewer {
          ... on SignatoryViewer {
            status
          }
        }
      }
    }
  `);
};