/**
 * @generated SignedSource<<2c4309f37dd21547678ed1ad4bf0fb1b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type sign_state_viewer$data = {
  readonly id: string;
  readonly " $fragmentType": "sign_state_viewer";
};
export type sign_state_viewer$key = {
  readonly " $data"?: sign_state_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"sign_state_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "sign_state_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "SignatoryViewer",
  "abstractKey": null
};

(node as any).hash = "769ab7e63264fb5ad3ba1580091cb4b5";

export default node;
