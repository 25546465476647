/**
 * @generated SignedSource<<9f6b5ea159ababc96f5a5e3d49ac99e4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SignatoryDocumentStatus = "APPROVED" | "OPENED" | "PREAPPROVED" | "REJECTED" | "SIGNED" | "%future added value";
export type SignatoryStatus = "DELETED" | "ERROR" | "OPEN" | "REJECTED" | "SIGNED" | "%future added value";
export type SignScreenQuery$variables = {};
export type SignScreenQuery$data = {
  readonly viewer: {
    readonly __typename: "SignatoryViewer";
    readonly documents: {
      readonly edges: ReadonlyArray<{
        readonly status: SignatoryDocumentStatus | null;
      }>;
    };
    readonly evidenceProviders: ReadonlyArray<{
      readonly __typename: string;
      readonly id?: string;
      readonly " $fragmentSpreads": FragmentRefs<"AllOfEvidenceProviderScreen_evidenceProvider" | "CriiptoVerifyButtons_evidenceProvider" | "DrawableEvidenceProviderScreen">;
    }>;
    readonly signer: boolean;
    readonly status: SignatoryStatus;
    readonly " $fragmentSpreads": FragmentRefs<"SignatoryBeacon_viewer">;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type SignScreenQuery = {
  response: SignScreenQuery$data;
  variables: SignScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "signer",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "SignatoryDocumentConnection",
  "kind": "LinkedField",
  "name": "documents",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SignatoryDocumentEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "domain",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientID",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "acrValues",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "alwaysRedirect",
  "storageKey": null
},
v10 = {
  "kind": "InlineFragment",
  "selections": [
    (v6/*: any*/),
    (v7/*: any*/),
    (v8/*: any*/),
    (v9/*: any*/)
  ],
  "type": "OidcJWTSignatureEvidenceProvider",
  "abstractKey": null
},
v11 = {
  "kind": "InlineFragment",
  "selections": [
    (v6/*: any*/),
    (v7/*: any*/),
    (v8/*: any*/),
    (v9/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "loginHint",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "scope",
      "storageKey": null
    }
  ],
  "type": "CriiptoVerifySignatureEvidenceProvider",
  "abstractKey": null
},
v12 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "requireName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minimumWidth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minimumHeight",
      "storageKey": null
    }
  ],
  "type": "DrawableSignatureEvidenceProvider",
  "abstractKey": null
},
v13 = [
  {
    "kind": "InlineFragment",
    "selections": [
      (v10/*: any*/),
      (v11/*: any*/)
    ],
    "type": "SignatureEvidenceProvider",
    "abstractKey": "__isSignatureEvidenceProvider"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SignScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "evidenceProviders",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "CriiptoVerifyButtons_evidenceProvider"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v5/*: any*/),
                    "type": "OidcJWTSignatureEvidenceProvider",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v5/*: any*/),
                    "type": "CriiptoVerifySignatureEvidenceProvider",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v4/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "DrawableEvidenceProviderScreen"
                      }
                    ],
                    "type": "DrawableSignatureEvidenceProvider",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v4/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "AllOfEvidenceProviderScreen_evidenceProvider"
                      }
                    ],
                    "type": "AllOfSignatureEvidenceProvider",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SignatoryBeacon_viewer"
              }
            ],
            "type": "SignatoryViewer",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SignScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "evidenceProviders",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  {
                    "kind": "TypeDiscriminator",
                    "abstractKey": "__isSignatureEvidenceProvider"
                  },
                  (v4/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "providers",
                        "plural": true,
                        "selections": [
                          (v0/*: any*/),
                          (v4/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": (v13/*: any*/),
                            "type": "OidcJWTSignatureEvidenceProvider",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v13/*: any*/),
                            "type": "CriiptoVerifySignatureEvidenceProvider",
                            "abstractKey": null
                          },
                          (v12/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "AllOfSignatureEvidenceProvider",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "SignatoryViewer",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7d6e391a408ac88ea086bd6589ee472c",
    "id": null,
    "metadata": {},
    "name": "SignScreenQuery",
    "operationKind": "query",
    "text": "query SignScreenQuery {\n  viewer {\n    __typename\n    ... on SignatoryViewer {\n      status\n      signer\n      documents {\n        edges {\n          status\n        }\n      }\n      evidenceProviders {\n        __typename\n        ...CriiptoVerifyButtons_evidenceProvider\n        ... on OidcJWTSignatureEvidenceProvider {\n          id\n        }\n        ... on CriiptoVerifySignatureEvidenceProvider {\n          id\n        }\n        ... on DrawableSignatureEvidenceProvider {\n          id\n          ...DrawableEvidenceProviderScreen\n        }\n        ... on AllOfSignatureEvidenceProvider {\n          id\n          ...AllOfEvidenceProviderScreen_evidenceProvider\n        }\n        id\n      }\n      ...SignatoryBeacon_viewer\n    }\n    id\n  }\n}\n\nfragment AllOfEvidenceProviderScreen_evidenceProvider on AllOfSignatureEvidenceProvider {\n  id\n  providers {\n    __typename\n    id\n    ... on OidcJWTSignatureEvidenceProvider {\n      id\n      ...CriiptoVerifyButtons_evidenceProvider\n    }\n    ... on CriiptoVerifySignatureEvidenceProvider {\n      id\n      ...CriiptoVerifyButtons_evidenceProvider\n    }\n    ... on DrawableSignatureEvidenceProvider {\n      id\n      ...DrawableEvidenceProviderScreen\n    }\n  }\n}\n\nfragment CriiptoVerifyButtons_evidenceProvider on SignatureEvidenceProvider {\n  __isSignatureEvidenceProvider: __typename\n  __typename\n  ... on OidcJWTSignatureEvidenceProvider {\n    id\n    domain\n    clientID\n    acrValues\n    alwaysRedirect\n  }\n  ... on CriiptoVerifySignatureEvidenceProvider {\n    id\n    domain\n    clientID\n    acrValues\n    alwaysRedirect\n    message\n    loginHint\n    scope\n  }\n}\n\nfragment DrawableEvidenceProviderScreen on DrawableSignatureEvidenceProvider {\n  id\n  requireName\n  minimumWidth\n  minimumHeight\n}\n\nfragment SignatoryBeacon_viewer on SignatoryViewer {\n  status\n  signer\n  documents {\n    edges {\n      status\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "479465d25cf247045b5213ca42e338e8";

export default node;
