import {Language} from './hooks/useTranslate';

export type Authentication = {
  'signatory-token': string | undefined
  'signatory-validation': string | undefined
}

export type StateValues = {
  authentication: Authentication,
  action: "sign" | undefined,
  language: Language | undefined
}

export class State {
  private store: Storage;
  private values: StateValues = {
    authentication: {
      'signatory-token': undefined,
      'signatory-validation': undefined
    },
    action: undefined,
    language: undefined
  };

  public key : string = Math.random().toString();

  constructor(store: Storage) {
    this.store = store;
  }

  hydrate(search: URLSearchParams) {
    if (search.get('state')) {
      this.key = search.get('state')!;
      let state = this.store.getItem(`state:${search.get('state')}`);
      if (state) {
        this.values = JSON.parse(state);
      }
    }
    
    if (search.get('signatory-token')) {
      this.values.authentication['signatory-token'] = search.get('signatory-token')!;
    }

    if (search.get('language') === 'EN_US') {
      this.values.language = search.get('language') as "EN_US";
    }
    if (search.get('language') === 'DA_DK') {
      this.values.language = search.get('language') as "DA_DK";
    }
    if (search.get('language') === 'SV_SE') {
      this.values.language = search.get('language') as "SV_SE";
    }
    if (search.get('language') === 'NB_NO') {
      this.values.language = search.get('language') as "NB_NO";
    }
  }

  save(values?: Partial<StateValues>) {
    this.values = {
      ...this.values,
      ...values
    };
    this.store.setItem(`state:${this.key}`, JSON.stringify(this.values));
  }

  setAuthentication<T extends keyof Authentication>(key: T, value: Authentication[T]) {
    this.values.authentication[key] = value;
  }

  get() {
    return this.values;
  }
}