import React from 'react';
import useTranslate from '../../../../hooks/useTranslate';

import styles from './SignatoryDeletedScreen.module.scss';

export default function SignatoryDeletedScreen() {
  const translate = useTranslate();
  return (
    <div className={styles['deleted-signatory-screen']}>
      <h1>{translate('Invalid signature link')}</h1>
      <p>
        {translate('The signature link you followed is no longer valid, please request a new one from the person or organization that invited you to sign')}.
      </p>
    </div>
  )
}