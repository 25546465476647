import React, {useState, useEffect, useCallback} from 'react';

export type Dimensions = {width: number | undefined, height: number | undefined};
export default function useContainerDimensions(ref: React.RefObject<HTMLElement | undefined>, props: any) {
  const getDimensions = useCallback(() => ({
    width: ref.current?.offsetWidth,
    height: ref.current?.offsetHeight
  }), [ref]);

  const [dimensions, setDimensions] = useState<Dimensions>(getDimensions());

  useEffect(() => {
    const handleResize = () => {
      setDimensions(getDimensions())
    }

    if (ref.current) {
      setDimensions(getDimensions())
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [getDimensions, ref, props]);

  return dimensions;
};