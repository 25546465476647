/**
 * @generated SignedSource<<4d44eb0839009d5acb336c988b807dbd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DownloadDocumentList_documents$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly id: string;
    };
    readonly " $fragmentSpreads": FragmentRefs<"DownloadDocumentListItem_document">;
  }>;
  readonly " $fragmentType": "DownloadDocumentList_documents";
};
export type DownloadDocumentList_documents$key = {
  readonly " $data"?: DownloadDocumentList_documents$data;
  readonly " $fragmentSpreads": FragmentRefs<"DownloadDocumentList_documents">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DownloadDocumentList_documents",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SignatoryDocumentEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DownloadDocumentListItem_document"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SignatoryDocumentConnection",
  "abstractKey": null
};

(node as any).hash = "e84b0eb4fe108cae7f9ad672ed980e6b";

export default node;
