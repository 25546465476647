import React, { useState } from 'react';
import { faSignature, faPen } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button from '../../../components/Button/Button';
import useTranslate from '../../../hooks/useTranslate';

import styles from './SignButton.module.scss';

interface Props {
  'data-testid'?: string,
  className?: string,
  disabled?: boolean,
  tooltip?: string,
  text?: string,
  onClick: () => void
}

export default function SignButton(props: Props) {
  const translate = useTranslate();
  const [showTooltip, setTooltip] = useState(false);

  const genericButton = (
    <Button
      variant="primary"
      large
      onClick={props.onClick}
      iconElement={(
        <i className="sign-icon">
          <FontAwesomeIcon icon={faSignature} />
          <FontAwesomeIcon icon={faPen} />
        </i>
      )}
      data-testid={props['data-testid']}
      className={props.className}
      disabled={props.disabled}
    >
      {props.text || translate('Sign')}
    </Button>
  );

  if (props.disabled && props.tooltip) {
    const handleClick = (event: React.MouseEvent) => {
      event.stopPropagation();
      setTooltip(true);
    }

    return (
      <div className={styles['tooltip-wrapper']} onMouseEnter={() => setTooltip(true)} onMouseLeave={() => setTooltip(false)} onClick={handleClick}>
        {showTooltip ? <div className={styles.tooltip}>{props.tooltip}</div> : null}
        {genericButton}
      </div>
    );
  }

  return genericButton;
}