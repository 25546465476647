import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserEdit } from '@fortawesome/pro-light-svg-icons';

import useTranslate from 'hooks/useTranslate';

import {EmptyErrorScreen} from 'screens/ErrorScreen';
import styles from 'screens/ErrorScreen/ErrorScreen.module.scss';
import SignButton from 'screens/SignatoryViewerScreen/components/SignButton';

interface Props {
  onRetry: () => void,
}

export default function DuplicateEvidenceErrorScreen(props: Props) {

  const translate = useTranslate();

  return (
    <EmptyErrorScreen>
      <FontAwesomeIcon icon={faUserEdit} />
      <h1>{translate('The e-ID you used has already previously been used to sign these documents.')}</h1>
      <p>
        {translate('Please make sure you are using your personal e-ID when signing.')}
      </p>
      <SignButton
        className={styles.retryButton}
        text={translate('Sign with new e-ID')}
        onClick={() => props.onRetry()}
      />
    </EmptyErrorScreen>
  );
}