import {useLazyLoadQuery} from 'react-relay';
import { graphql } from "react-relay";

import { AppQuery } from "./__generated__/AppQuery.graphql";

import SignatoryViewerScreen from './screens/SignatoryViewerScreen';
import AnonymousViewerScreen from './screens/AnonymousViewerScreen';
import { State } from './state';
import { LanguageContext, Language } from './hooks/useTranslate';
import { useOutlet } from 'react-router-dom';
import UnvalidatedSignatoryViewerScreen from 'screens/UnvalidatedSignatoryViewerScreen/UnvalidatedSignatoryViewerScreen';
import { useCallback, useState } from 'react';
import { SignStateProvider } from 'mutations/sign';

function App(props: {state: State, userAgent: string}) {
  const outlet = useOutlet();
  const [fetchKey, setFetchKey] = useState<string | undefined>();

  const data = useLazyLoadQuery<AppQuery>(
    graphql`
      query AppQuery {
        viewer {
          __typename

          ... on AnonymousViewer {
            authenticated
          }

          ... on SignatoryViewer {
            ui {
              language
            }
            ... SignatoryViewerScreen_viewer
            ... sign_state_viewer
          }
          ... on UnvalidatedSignatoryViewer {
            ui {
              language
            }
            ... UnvalidatedSignatoryViewerScreen_viewer
          }
        }
      }
    `,
    {},
    {
      fetchPolicy: 'network-only',
      fetchKey
    }
  );

  const handleRefresh = useCallback(() => {
    setFetchKey(Math.random().toString());
  }, []); 

  if (data.viewer.__typename === 'SignatoryViewer') {
    return (
      <LanguageContext.Provider value={data.viewer.ui.language as Language}>
        <SignStateProvider viewer={data.viewer}>
          {outlet ? outlet : (
            <SignatoryViewerScreen state={props.state} viewer={data.viewer} onNavigate={(url) => window.location.href = url} />
          )}
        </SignStateProvider>
      </LanguageContext.Provider>
    );
  }

  if (data.viewer.__typename === 'UnvalidatedSignatoryViewer') {
    return (
      <LanguageContext.Provider value={data.viewer.ui.language as Language}>
        <UnvalidatedSignatoryViewerScreen
          state={props.state}
          viewer={data.viewer}
          onRefresh={handleRefresh}
          userAgent={props.userAgent}
        />
      </LanguageContext.Provider>
    );
  }

  return (
    <LanguageContext.Provider value={props.state.get().language || "EN_US"}>
      <AnonymousViewerScreen />
    </LanguageContext.Provider>
  );
}


export default App;
