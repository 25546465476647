import { useFragment } from "react-relay";
import { graphql } from "react-relay";
import { OAuth2Error } from "@criipto/auth-js";
import { faPen, faSignature } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AllOfEvidenceProviderScreen_evidenceProvider$data, AllOfEvidenceProviderScreen_evidenceProvider$key } from "./__generated__/AllOfEvidenceProviderScreen_evidenceProvider.graphql";

import { AllOfEvidence, Evidence } from '../../SignScreen';
import { State } from '../../../../state';
import { useCallback, useEffect, useMemo, useState } from "react";
import DrawableEvidenceProviderScreen from "../DrawableEvidenceProviderScreen";
import ErrorScreen from "screens/ErrorScreen/ErrorScreen";

import styles from '../../SignScreen.module.scss';
import CriiptoVerifyButtons from '../../../../components/CriiptoVerifyButtons';
import useTranslate from "hooks/useTranslate";
import Button from "components/Button/Button";

interface Props {
  evidenceProvider: AllOfEvidenceProviderScreen_evidenceProvider$key
  state: State
  userAgent: string
  resetKey: string | null

  onCancel: () => void
  onEvidence: (evidence: Evidence) => void
  onError: (error: OAuth2Error) => void
}

interface SessionState {
  currentProviderId: string
  evidence: AllOfEvidence
}

export default function AllOfEvidenceProviderScreen(props: Props) {
  const evidenceProvider = useFragment(
    graphql`
      fragment AllOfEvidenceProviderScreen_evidenceProvider on AllOfSignatureEvidenceProvider {
        id
        
        providers {
          __typename
          id
          ... on OidcJWTSignatureEvidenceProvider {
            id
            ...CriiptoVerifyButtons_evidenceProvider
          }
          ... on CriiptoVerifySignatureEvidenceProvider {
            id
            ...CriiptoVerifyButtons_evidenceProvider
          }
          ... on DrawableSignatureEvidenceProvider {
            id
            ...DrawableEvidenceProviderScreen
          }
        }
      }
    `,
    props.evidenceProvider
  );

  const sessionId = `${evidenceProvider.id}:ALL_OF_V2`;
  const sessionState = useMemo(() => {
    /** Rehydrate session state */
    if (sessionStorage.getItem(sessionId)) {
      return JSON.parse(sessionStorage.getItem(sessionId)!) as SessionState;
    }
    return null;
  }, [sessionId]);

  const [currentProvider, setProvider] = useState(
    sessionState ?
      evidenceProvider.providers.find(s => s.id === sessionState.currentProviderId) ?? evidenceProvider.providers[0] :
      evidenceProvider.providers[0]
  );

  const [compositeEvidence, setCompositeEvidence] = useState<AllOfEvidence>(() => sessionState ? sessionState.evidence : {
    type: "allOf",
    id: evidenceProvider.id
  });

  useEffect(() => {
    if (!props.resetKey) return;

    setCompositeEvidence({
      type: "allOf",
      id: evidenceProvider.id
    });
    setProvider(evidenceProvider.providers[0]);
  }, [props.resetKey]);

  useEffect(() => {
    /** Save session state whenever evidence or step changes */
    const state : SessionState = {
      currentProviderId: currentProvider.id,
      evidence: compositeEvidence
    }
    sessionStorage.setItem(sessionId, JSON.stringify(state));
  }, [compositeEvidence, sessionId, currentProvider]);

  const translate = useTranslate();
  const handleCancel = () => {
    const first = currentProvider === evidenceProvider.providers[0];
    if (first) {
      props.onCancel();
    } else {
      const index = evidenceProvider.providers.indexOf(currentProvider) - 1;
      setProvider(evidenceProvider.providers[index]);
    }
  };

  const handleEvidence = useCallback((evidence: Evidence) => {
    const index = evidenceProvider.providers.indexOf(currentProvider);
    const last = index + 1 === evidenceProvider.providers.length;

    const updated : AllOfEvidence = {
      ...compositeEvidence,
      ...(evidence.type === 'jwt' ? {
        jwt: evidence
      } : evidence.type === 'drawable' ? {
        drawable: evidence
      } : {})
    };

    setCompositeEvidence(updated);
    if (last) {
      props.onEvidence(updated);
    } else {
      setProvider(evidenceProvider.providers[index + 1]);
    }
  }, [evidenceProvider, currentProvider, props.onEvidence]);

  if (!evidenceProvider.providers.length) {
    return (
      <ErrorScreen error={new Error('No sub providers for AllOfSignatureEvidenceProvider')} />
    );
  }

  if (currentProvider.__typename === 'CriiptoVerifySignatureEvidenceProvider' ||
      currentProvider.__typename === 'OidcJWTSignatureEvidenceProvider') {
    return (
      <div className={styles['sign-screen']}>
        <header>
          <i className="sign-icon">
            <FontAwesomeIcon icon={faSignature} />
            <FontAwesomeIcon icon={faPen} />
          </i>
          <h1>{translate('Choose how you want to sign')}</h1>
        </header>
  
        <div className={styles['providers']}>
          <CriiptoVerifyButtons
            userAgent={props.userAgent}
            evidenceProvider={currentProvider}
            onEvidence={handleEvidence}
            onError={props.onError}
            state={props.state}
            mayImmediateRedirect={!props.state.get().action}
            prompt="login"
            callbackRoute="/callback/sign"
          />
        </div>
                
        <Button variant="default" onClick={handleCancel} style={{marginTop: '10px'}}>
          {translate('Cancel')}
        </Button>
      </div>
    );
  }

  if (currentProvider.__typename === 'DrawableSignatureEvidenceProvider') {
    return (
      <DrawableEvidenceProviderScreen
        evidenceProvider={currentProvider}
        onCancel={handleCancel}
        onEvidence={handleEvidence}
      />
    );
  }

  return (
    <ErrorScreen error={new Error(`Unexpected evidence provider: '${currentProvider.__typename}', please contact support`)} />
  );
}
